<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="rows layout-column">
        <div class="layout-row row-1 layout-align-start-center">
          <div
            class="layout-column col-1 col-padding layout-align-center-end flex"
          >
            <section-title
              :partNo="part.id"
              :partTitle="part.title"
              data-depth="2.5"
              class="text-right parallax-item parallax-relative single-line"
              :show="showTitle"
            ></section-title>
          </div>
          <div
            class="
              layout-column
              col-padding col-2
              layout-align-start-start
              flex
            "
          >
            <lead
              :show="showLead"
              class="parallax-item parallax-relative"
              data-depth="2"
              >{{ data.meta.description }}</lead
            >
          </div>
        </div>
        <div class="layout-row row-2 layout-align-center-start">
          <timeline
            :decal="data.images.decal"
            :ink="data.images.inkWide"
            :show="showDiagram"
            :data="data.timelines"
          ></timeline>
        </div>
      </div>
    </div>
    <div class="container absolute fluid">
      <decal
        class="absolute bottom-center"
        :show="showDecal"
        :image="data.images.panorama"
        :parallaxDepth="1"
        :customTimeline="timelineDecal"
      ></decal>
    </div>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Lead from "@/components/ui/Lead.vue";
import Timeline from "@/components/ui/Timeline.vue";
import Decal from "@/components/ui/Decal.vue";

export default {
  name: "Chapter-2-3",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Lead,
    Timeline,
    Decal,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 200],
        ["showTitle", 600],
        ["showDiagram", 200],
        ["showDecal", 200],
        ["showLead", 1000],
      ],
      timelineDecal: {
        duration: 1,
        animations: {
          ease: Power1.easeOut,
          autoAlpha: 0,
          y: "+=50",
        },
      },
    };
  },
  computed: {},
};
</script>

<style scoped>
.row-1 {
  padding-bottom: 0.2%;
}
.col-1 {
  max-width: 44.8%;
}
.col-2 {
  padding-left: 0;
}
.part-lead {
  margin-top: -0.31rem;
}
/deep/ .lead {
  max-width: 640px;
}
.section-title {
  max-width: 30rem !important;
}
/deep/ .single-line.section-title {
  white-space: initial;
}
.section-decal {
  transform: translate(-50%, 10%);
  width: 102%;
  margin: 0;
  max-height: 9.125rem;
  max-height: 13vh;
}
.items-shown .section-decal {
  transform: translate(-50%, 10%) !important;
}
/deep/ .section-decal img {
  width: 100%;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1440px) {
  /deep/ .lead {
    max-width: 37em;
  }
}
@media screen and (max-width: 1200px) {
  .row-1 {
    flex-direction: column;
  }
  .row-1 > * {
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: var(--col-padding);
  }
  .section-title {
    max-width: 100% !important;
    margin-right: 10.9rem;
  }
  .part-lead {
    margin-bottom: 0.7rem;
  }
}
@media screen and (max-width: 1024px) {
  .section-title {
    margin-right: 13rem;
  }
}
@media screen and (max-width: 991px) {
  .section-title {
    margin-right: 0;
  }
  /deep/ .single-line.section-title > span {
    margin: 0;
    vertical-align: baseline;
  }
  .section-title {
    max-width: 11em !important;
  }
  /deep/ .single-line.section-title br {
    display: block;
  }
}
@media screen and (max-width: 639px) {
  /deep/ .lead {
    max-width: 29.8em;
  }
  .section-title {
    max-width: 5.1em !important;
  }
}
@media screen and (max-width: 540px) {
  .part-lead {
    margin-right: var(--col-padding-adjust);
  }
}
</style>
